<div class="cookie-container" *ngIf="isCookieConsentShown" [class.app-banner]="showBanner">
  <div class="container">
    <div class="row d-flex align-items-center m-0">
      <div class="col p-0">
        <span>เว็บไซต์นี้ใช้คุกกี้เพื่อพัฒนาประสบการณ์การใช้งานที่ดี อ่านรายละเอียดการใช้คุกกี้ตาม <a
          href="https://www.itax.in.th/pedia/นโยบายความเป็นส่วนตัว"
          target="_blank">นโยบายความเป็นส่วนตัว</a> ของเรา</span>
      </div>
      <div>
        <button type="button" class="btn btn-white-green ml-20px btn-reject" (click)="acceptCookieConsent()">
          ปฏิเสธ
        </button>
        <button type="button" class="btn btn-primary btn-ok" (click)="acceptCookieConsent()">
          ยอมรับ
        </button>
      </div>
    </div>
  </div>
</div>
