import {Component, HostBinding, OnInit} from '@angular/core';
import {ArticleItem, CoperateItem, ITaxItem, PersonalItem, TaxItem} from './footer';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @HostBinding('class') class = 'mt-auto';

  copyrightYear: number;

  personalItem = PersonalItem;
  coperateItem = CoperateItem;
  taxItem = TaxItem;
  articleItem = ArticleItem;
  iTaxItem = ITaxItem;
  isDetail = false;

  constructor(
  ) {
    this.copyrightYear = new Date().getFullYear();
  }

  ngOnInit(): void {
  }

}
