import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LandingPageRoutingModule} from './landing-page-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LandingPageRoutingModule
  ]
})
export class LandingPageModule {
}
