import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  private KEY = 'cookie-consent';
  isCookieConsentShown = false;
  showBanner = false;

  constructor(
    private router: Router,
  ) {
    const val = localStorage.getItem(this.KEY);
    this.isCookieConsentShown = val !== 'true';

    const isAndroid = /Android/.test(window.navigator.userAgent)
    const isMobile = /Mobi/.test(window.navigator.userAgent)
    const excludePage = (this.router.url.indexOf('/vat') == 0 || this.router.url.indexOf('/lite') == 0) 
    this.showBanner = (isAndroid && isMobile && !excludePage)
  }

  ngOnInit(): void {
  }

  acceptCookieConsent(): void {
    localStorage.setItem(this.KEY, 'true');
    this.isCookieConsentShown = false;
  }

}
