import {Component, OnInit} from '@angular/core';
import {NavbarItems} from './header';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navbarItems = NavbarItems;
  display = 'none';

  constructor() {
  }

  ngOnInit(): void {
  }

  openOverlay(display) {
    this.display = (display === 'none' ? 'initial' : 'none');
    document.getElementById('nav-overlay').style.display = (display === 'none' ? 'initial' : 'none');
  }

  isActive(link) {
    switch (link) {
      case ('/sme/'):
        return link === location.pathname || location.pathname === '/bnk/';
      default:
        return link === location.pathname;
    }
  }

}
