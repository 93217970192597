import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent implements OnInit {
  showBanner = false;

  constructor(
    private router: Router,
  ) { 
      const isAndroid = /Android/.test(window.navigator.userAgent)
      const isMobile = /Mobi/.test(window.navigator.userAgent)
      const excludePage = (this.router.url.indexOf('/vat') == 0 || this.router.url.indexOf('/lite') == 0) 
      this.showBanner = (isAndroid && isMobile && !excludePage)
  }

  ngOnInit(): void {
  }

}
