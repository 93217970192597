import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from './landing-page.component';

const routes: Routes = [{
  path: '',
  component: LandingPageComponent,
  children: [
    {
      path: 'vat',
      loadChildren: () => import('./calculator/calculator.module')
        .then(m => m.CalculatorModule)
    },
    {
      path: 'vat-result',
      loadChildren: () => import('./calculator-result/calculator-result.module')
        .then(m => m.CalculatorResultModule)
    },
    {
      path: 'lite',
      loadChildren: () => import('./calculator-tax/calculator-tax.module')
        .then(m => m.CalculatorTaxModule)
    },
    {
      path: 'lite-result',
      loadChildren: () => import('./calculator-tax-result/calculator-tax-result.module')
        .then(m => m.CalculatorTaxResultModule)
    },
    {
      path: '',
      loadChildren: () => import('./main/main.module').then(m => m.MainModule)
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageRoutingModule {
}
