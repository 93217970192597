import {Component, OnInit} from '@angular/core';
import {SocialTagsService} from '../../core/services/social-tags-service.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  readonly tagSocialTitle: string = 'iTAX คำนวณภาษี วางแผนภาษี อัพเดตล่าสุด';
  readonly tagSocialDescription: string = 'App คำนวณภาษี และวางแผนภาษี เพื่อเงินคืนภาษีสูงสุด - iTAX';
  readonly urlPrefix: string = environment.mainBaseUrl;

  constructor(
    private tagsService: SocialTagsService
  ) {
    this.tagsService.setTitleAndTags({
      tagSocialDescription: this.tagSocialDescription,
      tagSocialTitle: this.tagSocialTitle,
      urlPrefix: this.urlPrefix
    });

    this.tagsService.setCanonicalURL()
  }

  ngOnInit(): void {
  }

}
