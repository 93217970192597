import {environment} from '../../../../environments/environment';

export interface NavbarItem {
  link: string;
  label: string;
  isActive: boolean;
  id: string;
}

export const NavbarItems: NavbarItem[] = [
  {
    link: `/app/`,
    label: 'คำนวณภาษี',
    isActive: false,
    id: 'app'
  },
  {
    link: `${ environment.shopBaseUrl }`,
    label: 'ประกัน',
    isActive: false,
    id: 'insurance'
  },
  {
    link: `${ environment.shopBaseUrl }/fund`,
    label: 'กองทุน',
    isActive: false,
    id: 'fund'
  },
  {
    link: `/sme/`,
    label: 'ภาษีธุรกิจ',
    isActive: false,
    id: 'sme'
  },
  {
    link: `/media/`,
    label: 'ข่าวภาษี',
    isActive: false,
    id: 'media'
  }
];
